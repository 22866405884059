
.subHeading {
    font-size: 40px;
    font-weight: bold;
    color: #152039;
    line-height: 0.1;
    text-align: left;
    margin-left: 80px;
    margin-top: 80px;
    margin-bottom: 60px;
   
  }
  .subContent{
    color:#152039 ;
    margin-left: 80px;
    text-align: justify;
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    .subHeading{
      font-size: 30px;
    }
    .service-head{
      margin: 0 20px 0 -60px;
    }
    .subContent{
      font-size: 15px;
      margin-top: -20px;
    }
  }